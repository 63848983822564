<template>
  <div class="content">
    <div class="row">
      <div class="col-4">
        <input type="text" style="max-width: 300px;" v-model="query" placeholder="Search customers" class="form-control" v-on:keyup.enter="triggerSearch()" />
      </div>
    </div>

    <div class="row" v-if="!usersReady">
      <div class="col">
        <div class="mt-4">
          <div class="loading-placeholder">
            <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="usersReady">
      <div class="col">
        <div class="mt-2">
          <div class="table-container">
            <table class="table align-middle">
              <thead>
                <tr class="head">
                  <th class="ps-4">User</th>
                  <th class="text-center">Registered</th>
                  <th class="text-center">Marketing</th>
                  <th class="text-center">Imported</th>
                  <th class="text-center">Orders</th>
                  <!-- <th>Referred</th> -->
                  <th>Joined</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in usersList.data" :key="user.id">
                  <td class="ps-4">
                    <div class="d-flex flex-column">
                      <span class="bold blue">{{user.name}}</span>
                      <span class="pale small">{{user.email}}</span>
                    </div>
                  </td>
                  <td class="text-center">{{user.is_registered? '✓' : ''}}</td>
                  <td class="text-center">{{user.marketing_opt_in ? '✓' : ''}}</td>
                  <td class="text-center">{{user.is_imported ? '✓' : ''}}</td>
                  <td class="text-end pe-4" style="width: 200px;">
                    <span v-if="user.orders_count">
                      <span class="bold blue">£{{user.orders_amount | money}}</span>
                      <span class="pale mx-1">|</span>
                    </span>
                    <span v-if="user.orders_count" class="pale">
                      {{user.orders_count}} Order<span v-if="user.orders_count>1">s</span>
                    </span>
                  </td>
                  <!-- <td>
                    <span class="badge badge-sm bg-secondary" :class="{'bg-success': user.referrer_credited}">{{user.referred_by}}</span>
                  </td>-->
                  <td>
                    <span class="pale">{{user.created_at | formattedLongDate}}</span>
                  </td>
                  <td>
                    <a class="vave-btn" :href="'/customers/'+user.id">
                      <i class="fa fa-eye pe-1"></i>
                      View
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination class="paginator" :limit="4" align="center" :data="usersList" @pagination-change-page="getPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import moment from "moment";
import pagination from "laravel-vue-pagination";

export default {
  data() {
    return {
      usersList: {},
      usersReady: false,
      query: "",
    };
  },
  components: {
    pagination,
  },
  watch: {
    $route() {
      this.getUsers();
    },
  },
  mounted() {
    if (!this.usersList.data || this.usersList.data.length <= 0) {
      this.getUsers();
    }
  },
  filters: {
    money(value) {
      return (Math.round(value) / 100).toFixed(2);
    },
    formattedLongDate(value) {
      return value ? moment(String(value)).format("DD MMMM YY, HH:mm") : "";
    },
    formattedDate(value) {
      return value ? moment(String(value)).format("DD/MM/YYYY HH:mm") : "";
    },
  },
  methods: {
    triggerSearch() {
      if (this.query == "") {
        this.getUsers();
        return;
      }
      this.output = "";
      this.usersList = {};
      this.usersReady = false;
      axios
        .get("/api/admin/users/search?q=" + this.query + "&per_page=20&page=1")
        .then((response) => {
          if (response.data.data) {
            console.log(response.data)
            this.usersList = response.data;
            if (response.data.data.length == 0) {
              alert("No users found, try search different keywords");
            }
          }
        })
        .finally(() => {
          this.usersReady = true;
        });
    },
    isToday(user) {
      return moment(user.created_at).isSame(moment(), "day");
    },
    getPage(page) {
      this.$router.push("/customers?page=" + page);
    },
    getUsers() {
      let page = this.$route.query.page ? this.$route.query.page : 1;
      this.usersReady = false;
      this.output = "";
      this.usersList = [];
      axios
        .get("/api/admin/users?page=" + page + "&per_page=15")
        .then((response) => {
          if (response.data.data) {
            this.usersList = response.data;
          }
        })
        .finally(() => {
          this.usersReady = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.ucfirst {
  text-transform: capitalize;
}
.todayUser {
  background: #cae9d58a;
  &:hover {
    background: #cae9d5b6;
  }
}
</style>